import { useRoutes } from 'react-router-dom';

// routes
import {MainRoutes, Main, Login,Store, Subscribe, Contactus, StoreInner, Categories, CategoryDetail, Policypage } from './MainRoutes';
// import AuthenticationRoutes from './AuthenticationRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([MainRoutes, Main, Login, Store, Subscribe, Contactus, StoreInner, Categories, CategoryDetail, Policypage]);
}
